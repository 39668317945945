<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Independent Contractors
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="customers"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Info Order">
            <b-row>
              <b-col>
                <b-form-group id="input-group-2" label="Name:" label-for="i1">
                  <b-form-input id="i1" v-model="selected.name" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Email:" label-for="i2">
                  <b-form-input id="i2" v-model="selected.email" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Company:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.company_name" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>Avatar</label>
                <br />
                <img :src="selected.avatar_path" style="width: 100%" />
              </b-col>
              <b-col>
                <label>Picture ID</label>
                <br />
                <img :src="selected.file_identity" style="width: 100%" />
              </b-col>
              <b-col>
                <label>License</label>
                <br />
                <img :src="selected.file_licence" style="width: 100%" />
              </b-col>
              <b-col>
                <label>W9 Form</label>
                <br />
                <img :src="selected.file_form_service" style="width: 100%" />
              </b-col>
              <b-col>
                <label>Business Insurence</label>
                <br />
                <img :src="selected.file_seguro" style="width: 100%" />
              </b-col>
            </b-row>

            <b-button
              type="button"
              @click="selected = null"
              variant="danger"
              style="margin-right: 20px;"
            >Return</b-button>
            <b-button
              type="button"
              @click="deleteUser(selected.id)"
              variant="danger"
              style="margin-right: 20px;"
            >Delete user</b-button>
            <b-button
              type="button"
              @click="suspendUser(selected.id)"
              variant="warning"
              style="margin-right: 20px;"
            >Suspend</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "independent-contractors",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    deleteUser(id) {
      var r = confirm("Are you sure you want delete ?");
      if (r == true) {
        fetch("https://cleancity.krueltech.com/dev/api/api.php?action=deleteUser&id=" + id)
          .then(res => res.json())
          .then(data => {
            alert('User deleted successfully');
            window.location.reload()
          })
          .catch(console.log);
      }
    },

    suspendUser(id) {
      var r = confirm("Are you sure you want suspend ?");
      if (r == true) {
        fetch("https://cleancity.krueltech.com/dev/api/api.php?action=suspendUser&id=" + id)
          .then(res => res.json())
          .then(data => {
            alert('User deleted successfully');
            window.location.reload()
          })
          .catch(console.log);
      }
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  data() {
    return {
      customers: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Company Name", value: "company_name" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        {text: "Status", value: "status"}
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readIndependent")
      .then(res => res.json())
      .then(data => {
        this.customers = data.worker;
        //console.log('RES: '+ JSON.stringify(data.independent));
      })
      .catch(console.log);
  }
};
</script>
